import { template as template_d0f4f7c9da25448dacc434a724e4561d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_d0f4f7c9da25448dacc434a724e4561d(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
