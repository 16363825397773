import { template as template_5578d2fc0a8f43bca92acf7216e55dae } from "@ember/template-compiler";
const FKText = template_5578d2fc0a8f43bca92acf7216e55dae(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
