import { template as template_5aa34068ed9048baa49129d18c547a11 } from "@ember/template-compiler";
const WelcomeHeader = template_5aa34068ed9048baa49129d18c547a11(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
