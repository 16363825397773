import { template as template_cba07232259a4d5ca0684c08b02cfaad } from "@ember/template-compiler";
const FKControlMenuContainer = template_cba07232259a4d5ca0684c08b02cfaad(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
